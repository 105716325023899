import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Project from "../components/project"

const IndexPage = () => (
  <Layout>
    <SEO title="GH Labs" />
    <Project/>
  </Layout>
)

export default IndexPage
